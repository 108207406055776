<template>
  <div id="app">
    <h3>this is test page</h3>
    <button @click="clickBtn"> 点击发送请求</button>
  </div>
</template>
<style>

</style>
<script>
import axios from "axios";
export default {

  methods:{
    clickBtn(){
      axios.get("https://api.vnext.ailinkedlaw.com/api/languages/text?languageCode=zh-Hans&module=common&source=Web")
    }
  },
  mounted() {

    axios.get("https://api.vnext.ailinkedlaw.com/api/languages/text?languageCode=zh-Hans&module=common&source=Web")
    axios.get("https://api.vnext.ailinkedlaw.com/api/languages/text?languageCode=zh&module=common&source=Web")
    axios.get("https://api.vnext.ailinkedlaw.com/api/languages/text?languageCode=zh-Hans&module=login&source=Web")
    axios.get("https://api.vnext.ailinkedlaw.com/api/languages/text?languageCode=zh&module=login&source=Web")
    axios.get("https://api.vnext.ailinkedlaw.com/api/languages")
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
